/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.featured-article {
  background-color: var(--color-accent);
  padding-top: 2rem;
  padding-bottom: 2rem; }
  @media (min-width: 769px) {
    .featured-article {
      padding-top: 4rem;
      padding-bottom: 4rem; } }
  .featured-article .content-thumbnail {
    margin-bottom: 0; }
    @media (min-width: 769px) {
      .featured-article .content-thumbnail {
        -ms-flex-direction: row;
            flex-direction: row; } }
  .featured-article .content-thumbnail-figure {
    margin-bottom: 1.5rem; }
    @media (min-width: 769px) {
      .featured-article .content-thumbnail-figure {
        width: 50%;
        margin-bottom: 0; } }
    .featured-article .content-thumbnail-figure .content-thumbnail-link {
      margin-bottom: 0; }
  @media (min-width: 769px) {
    .featured-article .content-thumbnail-body {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-pack: center;
          justify-content: center;
      width: 50%;
      padding-left: 3.1557%; } }
  .featured-article .content-thumbnail-badges {
    margin-bottom: 1.5rem; }
  .featured-article .content-thumbnail-title {
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5rem; }
    @media (min-width: 769px) {
      .featured-article .content-thumbnail-title {
        font-size: 2rem;
        line-height: 2.625rem; } }
  .featured-article .content-thumbnail-icon {
    display: none !important; }
  .featured-article .content-thumbnail-description {
    margin: 0 0 1.5rem;
    display: block !important; }
    .featured-article .content-thumbnail-description p {
      font-size: 1rem; }
  .featured-article .content-thumbnail-readmore {
    display: block; }

.cetaphil_redesign .content-thumbnail-badges li {
  font-family: "Lateral-StandardBold"; }

.cetaphil_redesign .featured-article {
  background-color: #fff; }
